(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name championships.championship.controller:ChampionshipCtrl
     *
     * @description
     *
     */
    angular
      .module('neo.home.championships.championship')
      .controller('ChampionshipCtrl', ChampionshipCtrl);

    function ChampionshipCtrl($mdDialog, $mdToast, $filter, $stateParams, $state, AclService,
                              Championship, championship, genders, sports, categories, phases, programmes) {
      var vm = this;
      vm.ctrlName = 'ChampionshipCtrl';
      vm.can = AclService.can;
      vm.isAdmin = AclService.isAdmin;

      vm.championship = championship;
      vm.championship.typeId = 1;
      vm.sports = sports;
      vm.categories = categories;
      vm.genders = genders;
      vm.phases = buildPhases(phases);
      vm.programmes = programmes;
      vm.championshipTypes = buildChampionshipTypes();


      vm.updateChampionship = updateChampionship;

      vm.edit = false;

      vm.hasPermissionToModifyMatches = hasPermissionToModifyMatches;
      vm.buttons = {
        dial: true,
        icon: 'more_vert',
        tooltip: 'ACTIONS',
        actions: []
      };

      if (vm.championship.owner) {
        vm.buttons.actions.push({
          icon: 'edit',
          tooltip: 'EDIT',
          execute: enableEdit
        });
        vm.buttons.actions.push({
          icon: 'delete',
          attrClass: 'md-warn',
          tooltip: 'DELETE',
          execute: removeChampionship
        });
        vm.buttons.actions.push({
          icon: 'playlist_add',
          tooltip: 'CHAMPIONSHIPS.ASSIGN_TEAMS',
          execute: assignTeams
        });
        vm.buttons.actions.push({
          buttonImage: 'images/draw.svg',
          tooltip: 'CHAMPIONSHIPS.DRAW.DRAW_CHAMPIONSHIP',
          execute: drawChampionship
        });
      }

      if (isNotJvp(vm.championship) && vm.championship.hasBeenDrawn) {
        vm.buttons.actions.push({
          icon: 'format_list_numbered',
          tooltip: 'CHAMPIONSHIPS.CLASSIFICATION',
          execute: showClassification
        });
      }

      if (vm.championship.hasBeenDrawn && hasPermissionToModifyMatches()) {
        vm.buttons.actions.push({
          icon: 'assignment',
          tooltip: 'CHAMPIONSHIPS.ASSIGN_MATCHES',
          execute: assignMatches
        });
      }

      if (vm.isAdmin() || (vm.championship.owner && vm.championship.editableMatches)) {
        vm.buttons.actions.push({
          icon: 'settings',
          tooltip: 'CHAMPIONSHIPS.EDIT_CALENDAR',
          execute: editMatches
        });
      }

      function buildChampionshipTypes() {
        return [
          {
            id: 1,
            name: 'Lliga'
          }
        ];
      }

      function buildPhases(phases) {
        if (vm.isAdmin()) {
          return phases;
        }
        else if (vm.championship.owner) {
          return filterLocalPhases(phases);
        }
        else {
          return [findItemById(phases, vm.championship.phaseId)];
        }

      }

      function filterLocalPhases(phases) {
        return _.filter(phases, function (phase) {
          return phase.type === 'LOCAL';
        });
      }


      function findItemById(collection, itemId) {
        return _.find(collection, function (i) {
          return i.id === itemId;
        });
      }
      function hasPermissionToModifyMatches() {
        return AclService.isAdmin() || (vm.championship.owner && vm.championship.editableMatches) || vm.championship.editableMatches;
      }

      function isNotJvp(championship) {
        return !championship.jvp;
      }

      function enableEdit() {
        vm.edit = true;
        vm.buttons.dial = false;
      }

      function disableEdit() {
        vm.edit = false;
        vm.buttons.dial = true;
      }

      function updateChampionship() {
        Championship.update({id: $stateParams.championshipId}, vm.championship, function () {
          disableEdit();
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('CHAMPIONSHIPS.SUCCESSFUL_UPDATE'))
              .position('bottom left')
              .hideDelay(3000)
          );
          $state.reload();
        }, function (err) {
          var message = err.data.message || 'ERROR_UPDATE';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
      }

      function removeChampionship() {

        $mdDialog.show({
          controller: function DialogController($scope, $mdDialog, $filter) {
            $scope.alertText = $filter('translate')('ALERT');
            $scope.textContent = $filter('translate')('CHAMPIONSHIPS.DELETE_ALERT');
            $scope.cancelText = $filter('translate')('CANCEL');
            $scope.deleteText = $filter('translate')('DELETE');

            $scope.hide = function () {
              $mdDialog.hide();
            };

            $scope.cancel = function () {
              $mdDialog.cancel();
            };

            $scope.delete = function () {
              //then
              $mdDialog.hide();
            };
          },
          templateUrl: 'home/championships/championship/delete-confirmation.tmpl.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        })
          .then(function () {
            Championship.delete({id: vm.championship.id}, function () {
              $state.go('home.championships', {}, {reload: true});
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')('CHAMPIONSHIPS.SUCCESSFUL_DELETE'))
                  .position('bottom left')
                  .hideDelay(3000)
              );
            }, function (err) {
              var message = err.data || 'ERROR_DELETE';
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')(message))
                  .position('bottom left')
                  .hideDelay(3000)
              );
            });
          });
      }

      function assignTeams() {
        $state.go('home.championships.championship.assignTeams');
      }

      function drawChampionship() {
        $state.go('home.championships.championship.draw');
      }

      function showClassification() {
        $state.go('public.classification', {championshipId: $stateParams.championshipId});
      }

      function assignMatches() {
        $state.go('home.championships.championship.assignMatches');
      }

      function editMatches() {
        $state.go('home.championships.championship.editMatches');
      }

    }
  }()
);

